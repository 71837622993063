import React from 'react';
import homedata from '../../data/homedata.json';

const WhatsAppIcon = () => {
    const numero = homedata.contact.contactinfo.phone.info;

    const handleWhatsAppClick = () => {
        window.open('https://wa.me/' + numero, '_blank');
    };

    return (
        <div className="whatsapp-icon" onClick={handleWhatsAppClick}>
            <i className="icofont-whatsapp" aria-hidden="true"></i>
        </div>
    );
};

export default WhatsAppIcon;
