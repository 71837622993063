import React from 'react';

const GoogleMap = () => {
  return (
    <div className="row">
      {/* Contenedor responsivo */}
      <div className="embed-responsive embed-responsive-16by9">
        {/* Mapa de Google */}
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3764.302053719907!2d-99.10695262478761!3d19.35606958190703!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTnCsDIxJzIxLjkiTiA5OcKwMDYnMTUuOCJX!5e0!3m2!1spt-BR!2smx!4v1719080386921!5m2!1spt-BR!2smx" 
          width="auto" 
          height="400" 
          className="embed-responsive-item"
          style={{ border: '0' }} 
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default GoogleMap;

